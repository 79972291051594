import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom/dist";
import Logo from "../../assets/images/Logo/Logo.png";

const TopNavBanner = ({ className }) => {
  //   const location = useLocation();
  //   console.log(location);
  let location = window.location;

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div className={`top-nav-bar ${className}`}>
        <Navbar bg="" expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              {/* <Logo /> */}
              <img src={Logo} alt="produtc-logo"  />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={handleNavToggle}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="ms-auto"
                defaultActiveKey="/"
                onClick={handleNavToggle}
              >
                <Nav.Item className={"p-3"}>
                  <Nav.Link href="/" onClick={handleNavToggle}>
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Nav.Link
                    href={`${location.origin}#feature`}
                    onClick={handleNavToggle}
                  >
                    Features
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Nav.Link
                    href={`${location.origin}#about-us`}
                    onClick={handleNavToggle}
                  >
                    About us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Nav.Link
                    href={`${location.origin}#reviews`}
                    onClick={handleNavToggle}
                  >
                    Reviews
                  </Nav.Link>
                </Nav.Item>
                <NavLink to="#about-us" onClick={handleNavToggle}>
                  <Button className="btn-theme-default btn-contact-us">
                    contact Us
                  </Button>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default TopNavBanner;
